export const kz = {
    //Выборка
    orders: "Тапсырыстар",
    newOrders: "Жаңа Тапсырыстар",
    menu: "Меню",
    tables: "Мейрамхана үстелдері",
    exit: "Шығу",
    adminPanel: "Әкімші панелі",
// Заказы
    allOrders: "Барлық тапсырыстар",
    inProcess: "Орындалуда",
    ordersReady: "Орындалған",
    totalPrice: "Жалпы сома",
    orderID: "Тапсырыс ID",
    time: "Тапсырыс уақыты",
    fioClient: "Клиенттің аты-жөні",
    orderPrice: "Тапсырыстың құны",
    orderStatus: "Тапсырыс мәртебесі",
    return: "Қайтару",
    tableNumber: "Үстел нөмірі",
    prevPage: "Алдыңғы бет",
    page: "Бет",
    nextPage: "Келесі бет",
    rejected: "Қабылданбаған",
    orderIsReady: "Аяқталған",
//время
    yesterday: "Кеше",
    today: "Бүгін",
    january: "Қаңтар",
    february: "Ақпан",
    march: "Наурыз",
    april: "Сәуір",
    may: "Мамыр",
    june: "Маусым",
    july: "Шілде",
    august: "Тамыз",
    september:"Қыркүйек",
    october: "Қазан",
    november: "Қараша",
    december: "Желтоқсан",
//components
    search: "Іздеу",
    clear: "Өшіру",
    use: "Қолдану",
    add: "Қосу",
    reject: "Қабылдамау",
    accept: "Қабылдау",
    delete: "Жою",
    save: "Сақтау",
    discard: "Болдырмау",
    edit: "Түзету",
//sort
   byDate: "Күні бойынша",
   byTime: "Уақыт бойынша",
   byIncreasingSum: "Сома өсуімен",
   byDecreasingSum: "Сома кемуімен",
   newFirst: "Алдымен жаңалары",
   oldFirst: "Алдымен ескілер",

//Новые заказы
order: "Тапсырыс",
changeOrder: "Тапсырысты өзгерту",
clientComment: "Клиент түсініктемелері",
currentOrder: "Ағымдағы тапсырыс",
emptyText: "Сізде жаңа тапсырыстар жоқ",
emptyTextAll: "Барлық ағымдағы тапсырыстар осы бетте көрсетіледі",


// Меню
restautantMenu: "Мейрамхана менюсы",
dishes: "Тағамдар",
categories: "Санаттар",
addPosition: "Позиция қосу",
addСategory: "Санат қосу",
editCategory: "Санатты өзгерту",
next: "Растау",
deleteCategory: "Санатты жойғыңыз келетініне сенімдісіз бе?",
deletePosition: "Позицияны жойғыңыз келетініне сенімдісіз бе?",
positionName: "Позиция атауы",
chooseCategory: "Санат таңдау",
typeDescription: "Сипаттама енгізіңіз",
addPrice: "Бағаны енгізіңіз",
insertImage: "Еңгізу",

//profile
mainInfo: "Негізгі ақпарат",
accountInfo: "Аккаунт мәліметтері",
requisites: "Реквизиттер",
restaurantDescription: "Мекеменің сипаттамасы",
address: "Мекенжай",
averagePrice: "Орташа чек",
service: "Қызмет",
position: "Қызмет атауы",
IIN: "ЖСН",
telephone: "Телефон",
email: "Пошта",
password: "Пароль",
changePassword: "Пароль өзгерту",
currentPassowrd: "Ағымдағы пароль",
newPassword: "Жаңа пароль",
confirmNewPassword: "Жаңа парольді растаңыз",
organizationName: "Компанияның атауы",
BINorganization: "БСН",
bank: "Банк",
BIK: "Бик",
IBAN: "IBAN",
KBE: "Кбе",
currency: "Валюта",
//placeholders
chooseCurrentCategory: "Санатты таңдаңыз",
typeContantNumber: "Байланыс телефонын жазыңыз",
typeIIN: "ИИН жазыңыз",
typeFIOResposiblePerson: "Жауапты адамның аты-жөнін жазыңыз",
organizationNameExpample: "Мысалы TOO Lucky You",
typeBINorganization: "БСН жазыңыз",
typeBank: "Банк жазыңыз",
typeBIK: "БИК жазыңыз",
typeKBE: "Кбе жазыңыз",
typeIBAN: "Есептік шот номерін жазыңыз",
typeDescriptionMainInfo: "Сипаттама жазыңыз. Мысалы, қандай тағамдар немесе қандай дәмді және әдемі.",
typeName: "Атауын жазыңыз",
typeAddress: "Мекенжайды жазыңыз",
typeSum: "Сома теңгемен",
typeService: "Қызмет көрсету %",
//Столы заведения
tableName: "Үстел атауы",
QRcode: "QR-код",
action: "Әрекеттер",
QRtext: "Сақтаудан кейін QR коды автоматты түрде жасалады",
deleteQRText:"Үстелді шынымен жойғыңыз келе ме?",
download: "Жүктеп алу",
choosen: "Таңдалған",
itemSearch: "Іздеу...",
remove: "Жою"

}