export const en = {
    //Выборка
    orders: "Orders",
    newOrders: "New orders",
    menu: "Menu",
    tables: "Tables",
    exit: "Exit",
    adminPanel: "Admin Panel",
// Заказы
    allOrders: "All orders",
    inProcess: "In process",
    ordersReady: "Completed orders",
    totalPrice: "Total price",
    orderID: "Order ID",
    time: "Order time",
    fioClient: "Customer name",
    orderPrice: "Order price",
    orderStatus: "Order status",
    return: "Refund",
    tableNumber: "Table number",
    prevPage: "Previous page",
    page: "Page",
    nextPage: "Next page",
    rejected: "Rejected",
    orderIsReady: "Completed",
//время
    yesterday: "Yesterday",
    today: "Today",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september:"September",
    october: "October",
    november: "November",
    december: "December",
//components
    search: "Search",
    clear: "Clear",
    use: "Use",
    add: "Add",
    reject: "Reject",
    accept: "Accept",
    delete: "Delete",
    save: "Save",
    discard: "Cancel",
    edit: "Edit",
//sort
   byDate: "By Date",
   byTime: "By Time",
   byIncreasingSum: "Ascending order",
   byDecreasingSum: "Descending order",
   newFirst: "Newest",
   oldFirst: "Oldest",

//Новые заказы
order: "Order",
changeOrder: "Edit order",
clientComment: "Customer comment",
currentOrder: "Actual order",
emptyText: "You have no new orders",
emptyTextAll: "All actual orders will be displayed on this page",


// Меню
restautantMenu: "Menu",
dishes: "Dishes",
categories: "Categories",
addPosition: "Add product",
addСategory: "Add category",
editCategory: "Edit category",
next: "Confirmation",
deleteCategory: "Are you sure you want to delete a category?",
deletePosition: "Are you sure you want to delete a product?",
positionName: "Product name",
chooseCategory: "Select category",
typeDescription: "Write a description",
addPrice: "Enter price",
insertImage: "Upload photo",

//profile
mainInfo: "Main Info",
accountInfo: "Account details",
requisites: "Requisites",
restaurantDescription: "Description of the restaurant",
address: "Address",
averagePrice: "Average price",
service: "Service",
position: "Job title",
IIN: "IIN",
telephone: "Phone number",
email: "Email",
password: "Password",
changePassword: "Change password",
currentPassowrd: "Current password",
newPassword: "New password",
confirmNewPassword: "Confirm new password",
organizationName: "Organization name",
BINorganization: "Organization BIN",
bank: "Bank",
BIK: "BIK",
IBAN: "IBAN",
KBE: "KBE",
currency: "Currency",
//placeholders
chooseCurrentCategory: "Select category",
typeContantNumber: "Type in contact number",
typeIIN: "Write IIN",
typeFIOResposiblePerson: "Write full name of responsible person",
organizationNameExpample: "Example TOO Lucky You",
typeBINorganization: "Type in BIN",
typeBank: "Type in Bank",
typeBIK: "Type in BIK",
typeKBE: "Type in KBE",
typeIBAN: "Type in IBAN",
typeDescriptionMainInfo: "Write some description. For example, what kind of cuisine or how delicious and beautiful it is.",
typeName: "Type in name",
typeAddress: "Type in address",
typeSum: "Amount in tg",
typeService: "Service in %",
//Столы заведения
tableName: "Table name",
QRcode: "QR-code",
action: "Action",
QRtext: "QR code will be automatically generated after saving",
deleteQRText:"Are you sure you want to delete the table??",
download: "Download",
choosen: "Selected",
itemSearch: "Search...",
remove: "Remove"

}