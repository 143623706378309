export const ru = {
    //Выборка
    orders: "Заказы",
    newOrders: "Новые Заказы",
    menu: "Меню",
    tables: "Столы заведения",
    exit: "Выйти",
    adminPanel: "Админ панель",
// Заказы
    allOrders: "Все заказы",
    inProcess: "В процессе",
    ordersReady: "Завершенные",
    totalPrice: "Общая сумма",
    orderID: "ID заказа",
    time: "Время заказа",
    fioClient: "ФИО клиента",
    orderPrice: "Сумма заказа",
    orderStatus: "Статус заказа",
    return: "Возврат",
    tableNumber: "Номер стола",
    prevPage: "Предыдущая страница",
    page: "Страница",
    nextPage: "Следующая страница",
    rejected: "Отказан",
    orderIsReady: "Завершен",
//время
    yesterday: "Вчера",
    today: "Cегодня",
    january: "Январь",
    february: "Февраль",
    march: "Март",
    april: "Апрель",
    may: "Май",
    june: "Июнь",
    july: "Июль",
    august: "Август",
    september:"Сентябрь",
    october: "Октябврь",
    november: "Март",
    december: "декабрь",
//components
    search: "Искать",
    clear: "Очистить",
    use: "Использовать",
    add: "Добавить",
    reject: "Отклонить",
    accept: "Принять",
    delete: "Удалить",
    save: "Сохранить",
    discard: "Отменить",
    edit: "Редактировать",
//sort
   byDate: "По дате",
   byTime: "По времени",
   byIncreasingSum: "По возврастанию суммы",
   byDecreasingSum: "По убыванию суммы",
   newFirst: "Сначала новые",
   oldFirst: "Сначала старые",

//Новые заказы
order: "Заказ",
changeOrder: "Изменить заказ",
clientComment: "Комментарии клиента",
currentOrder: "Актуальный заказ",
emptyText: "У вас нет новых заказов",
emptyTextAll: "Все актуальные заказы будут отображаться на этой странице",


// Меню
restautantMenu: "Меню заведения",
dishes: "Блюда",
categories: "Категории",
addPosition: "Добавить позицию",
addСategory: "Добавить категорию",
editCategory: "Редактировать категорию",
next: "Подтвердение",
deleteCategory: "Вы действительно хотите удалить категорию?",
deletePosition: "Вы действительно хотите удалить позицию?",
positionName: "Название позиции",
chooseCategory: "Выбрать категорию",
typeDescription: "Введите описание",
addPrice: "Введите цену",
insertImage: "Загрузить",

//profile
mainInfo: "Основная информация",
accountInfo: "Данные аккаунта",
requisites: "Реквизиты",
restaurantDescription: "Описание заведения",
address: "Адрес",
averagePrice: "Средний чек",
service: "Обслуживание",
position: "Должность",
IIN: "ИИН",
telephone: "Телефон",
email: "Почта",
password: "Пароль",
changePassword: "Изменить пароль",
currentPassowrd: "Текущий пароль",
newPassword: "Новый пароль",
confirmNewPassword: "Подтвердите новый пароль",
organizationName: "Наименование организации",
BINorganization: "БИН организации",
bank: "Банк",
BIK: "Бик",
IBAN: "IBAN",
KBE: "Кбе",
currency: "Валюта",
//placeholders
chooseCurrentCategory: "Выберите категорию",
typeContantNumber: "Напишите контактный телефон",
typeIIN: "Напишите ИИН",
typeFIOResposiblePerson: "Напишите ФИО ответственного лица",
organizationNameExpample: "Напримерб TOO Lucky You",
typeBINorganization: "Напишите БИН",
typeBank: "Напишите банк",
typeBIK: "Напишите БИК",
typeKBE: "Напишите Кбе",
typeIBAN: "Напишите номер расчетного счета",
typeDescriptionMainInfo: "Напишите какое-то описание. Например, какая кухня или как тут вкусно и красиво.",
typeName: "Напишите название",
typeAddress: "Напишите адрес",
typeSum: "Cумма в тг",
typeService: "Обслуживание в %",
//Столы заведения
tableName: "Название столика",
QRcode: "QR-код",
action: "Действия",
QRtext: "QR код будет автоматически сгенерирован после сохранения",
deleteQRText:"Вы действительно хотите удалить столик?",
download: "Скачать",
choosen: "Выбрано",
itemSearch: "Поиск...",
remove: "Убрать"

}